import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo';
import Image from '../../../../components/image';
import Validations from '../../../../utils/validations';
import Analytics from '../../../../utils/analytics';
import Origin from '../../../../utils/origin';
import Bootstrap from '../../../../utils/bootstrap';
import axios from 'axios';
import $ from 'jquery';
import './styles/main.scss';

const Blue3PartnershipPage = (location) => {
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState();

  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [phone, setPhone] = useState('');
  let [ddd, setDdd] = useState('');
  let [patrimony, setPatrimony] = useState('');

  let [nameError, setNameError] = useState('');
  let [emailError, setEmailError] = useState('');
  let [phoneError, setPhoneError] = useState('');
  let [patrimonyError, setPatrimonyError] = useState('');

  const ddds = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31,
    32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73,
    74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92,
    93, 94, 95, 96, 97, 98, 99];

  const patrimonyList = [
    {
      value: '10000',
      text: 'Até R$ 10 mil'
    },
    {
      value: '50000',
      text: 'Até R$ 50 mil'
    },
    {
      value: '100000',
      text: 'Até R$ 100 mil'
    },
    {
      value: '300000',
      text: 'Até R$ 300 mil'
    },
    {
      value: '1000000',
      text: 'Até R$ 1 milhão'
    },
    {
      value: '5000000',
      text: 'Até R$ 5 milhões'
    },
    {
      value: '5000001',
      text: 'Acima de R$ 5 milhões'
    },
    {
      value: '0',
      text: 'Não tenho valor para investir'
    },
  ];

  const doubts = [
    {
      title: 'Qual a relação entre o Análise de Ações e a Blue3?',
      text: 'O Análise de Ações é uma plataforma de análise de investimentos independente. Nossa relação com a Blue3 é somente de parceria, com o objetivo de oferecer aos nossos usuários um suporte ainda mais completo na hora de investir.'
    },
    {
      title: 'Como funciona a assessoria da Blue3?',
      text: 'A assessoria da Blue3 funciona como uma ponte entre você e as melhores oportunidades disponíveis na plataforma XP. A Blue3 não faz recomendação e nem movimenta diretamente seu dinheiro. O time de profissionais da Blue3 estuda o mercado para te ajudar a tomar as melhores decisões na hora de investir, de forma totalmente isenta e transparente.'
    },
    {
      title: 'Quanto custa investir com a Blue3?',
      text: 'Não existem custos para o serviço de assessoria da Blue3. Você não paga nada a mais por ter uma equipe de assessores ao seu lado.'
    },
    {
      title: 'Qual a relação entre a XP e a Blue3?',
      text: 'A Blue3 é uma empresa de investimentos credenciada à rede XP, regularizada e fiscalizada por todos os órgãos regulatórios envolvidos. Além disso, está em processo no Banco Central para a abertura de uma nova corretora, da qual a XP é uma de suas sócias.'
    },
    {
      title: 'A Blue3 é um banco?',
      text: 'A Blue3 não é um banco e sim uma assessoria de investimentos. Não existe movimentação de dinheiro físico em suas unidades. O único foco da empresa é o investimento, com expertise de 12 anos no mercado.'
    },
    {
      title: 'Assessor de investimentos faz o mesmo que o gerente?',
      text: 'Não. O gerente de banco é o profissional que cuida do dia a dia da sua conta, como transferências e pagamentos. Já o assessor é um profissional totalmente focado em investimentos, sem vínculos com nenhuma instituição financeira tradicional. Assim, ele pode te atender com total isenção, colocando os seus interesses sempre em primeiro lugar. Além disso, o gerente fica preso a metas e a produtos da própria prateleira para te oferecer. Por outro lado, o assessor tem acesso a todos os produtos do mercado, ampliando e muito suas oportunidades de diversificação e rendimento.'
    },
    {
      title: 'Meu dinheiro estará tão seguro quanto no banco?',
      text: 'Fique tranquilo, seus recursos são movimentados de forma segura, digital e rastreável, com o controle de todos os órgãos reguladores envolvidos, como a Comissão de Valores Mobiliários, CVM. Seu dinheiro sempre estará na outra ponta, no emissor dos papéis, como no Tesouro Nacional no caso do Tesouro Direto, por exemplo.'
    },
    {
      title: 'Tenho que sacar o dinheiro investido no banco para investir com a Blue3?',
      text: 'Não é preciso retirar o dinheiro, apenas fazer uma mudança de custódia. Todo o processo é feito de forma segura e fiscalizada. Você terá o acompanhamento de um profissional que é qualificado para isso. Ele vai te auxiliar durante todo esse processo para que ele seja rápido, fácil e acessível.'
    },
  ];

  const features = [
    {
      title: 'Alocações internacionais',
      text: 'Invista em economias fortes e em ações das empresas que mais crescem no exterior.'
    },
    {
      title: 'Tranquilidade',
      text: 'Mesa de renda variável eleita a melhor do país pela XP por dois semestres consecutivos.'
    },
    {
      title: 'Acompanhamento',
      text: 'Contato próximo com os brokers que avaliam as melhores opções para o seu momento de vida.'
    },
  ];

  const steps = [
    {
      title: 'Atendimento',
      text: 'Conversa com o especialista para conhecer seu momento de vida e objetivos no médio, curto e longo prazo.'
    },
    {
      title: 'Estudo',
      text: 'Time de alocação e mesa de renda variável entram em campo para te ajudar a encontrar as melhores oportunidades.'
    },
    {
      title: 'Relacionamento',
      text: 'Contatos de alinhamento para discutir decisões e orientar sobre possíveis otimizações na sua carteira de investimentos.'
    },
  ];

  const feedbacks = [
    {
      author: 'Thiago V. - Cliente Blue3',
      text: '"Meu assessor é excelente, sempre disponível para tirar dúvidas e indicar os melhores investimentos para uma carteira diversificada. Além disso ele me mantém informado do mercado com mensagem diárias sobre abertura e fechamento do mercado de ações e com informações dos principais jornais logo de manhã. Um dia, lendo as notícias matinais enviada por ele, de que a Eletrobras havia fechado em baixa de 10%, acabei comprando ações da empresa por oportunidade."'
    },
    {
      author: 'Stanley J. – Cliente Blue3',
      text: '"A minha assessora de investimentos é um grande anjo da guarda financeiro. Além de ser cordial e profissional, está sempre mantendo contato e é super prestativa; é extremamente capacitada para solução de eventuais problemas, sempre elucidando com clareza, de forma educada e com muita paciência as dúvidas questionadas. Tudo isto, graças ao ótimo conhecimento técnico que possui, ensejando assim, ofertas de investimentos satisfatórias e adequadas ao meu perfil. Ademais, quero PARABENIZAR TODOS OS MEMBROS que compõem essa tão conceituada empresa: "BLUE 3", que na verdade deveria ser "BLUE 10" face aos seus excelentes profissionais."'
    },
    {
      author: 'Leonardo M. - Cliente Blue3',
      text: '"Meu assessor tem o total domínio técnico dos produtos e se comunica com muita clareza e profissionalismo. Excelente frequência de contato com o cliente e acima de tudo ética para buscar sempre o melhor produto para meu perfil de investimento.  Ele traz uma segurança e confiança nos produtos e inclusive o seu atendimento me fez indicar seu trabalho para minha família e amigos próximos. Sem dúvidas teremos um tempo longo juntos nesse projeto."'
    },
  ];

  const rewards = [
    'rewards-1.png',
    'rewards-2.png',
    'rewards-3.png',
    'rewards-4.png',
    'rewards-5.png',
    'rewards-6.png',
  ];

  const media = [
    {
      title: 'InfoMoney',
      image: 'media-infomoney.png',
      url: 'https://www.infomoney.com.br/minhas-financas/melhor-assessoria-de-2021-blue3-mira-ipo/'
    },
    {
      title: 'O Globo',
      image: 'media-oglobo.png',
      url: 'https://blogs.oglobo.globo.com/capital/post/bluetrade-assessoria-da-xp-com-r-10-bi-vira-blue3-com-plano-de-se-tornar-corretora-e-fazer-ipo.html'
    },
    {
      title: 'Forbes',
      image: 'media-forbes.png',
      url: 'https://forbes.com.br/forbes-money/2021/06/xp-inc-e-blue3-anunciam-criacao-de-nova-corretora-de-investimentos/'
    },
    {
      title: 'E-Investidor',
      image: 'media-einvestidor.png',
      url: 'https://einvestidor.estadao.com.br/mercado/impacto-alta-selic-ibovespa-acoes/'
    },
    {
      title: 'IstoÉ Dinheiro',
      image: 'media-istoedinheiro.png',
      url: 'https://www.istoedinheiro.com.br/impulsionado-por-commodities-ibovespa-sobe-apesar-de-bcs-mais-duros-no-mundo/'
    },
  ];

  const highlights = [
    {
      title: '+12 ANOS',
      text: 'ajudando a construir legados.',
    },
    {
      title: '+17 MIL',
      text: 'clientes que confiam na Blue3.',
    },
    {
      title: '+14 BILHÕES',
      text: 'de reais sob custódia.',
    }
  ];

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'name') {
      setNameError('');
      setName(value);
    } else if (name === 'email') {
      setEmailError('');
      setEmail(value);
    } else if (name === 'phone') {
      setPhoneError('');
      setPhone(value);
    } else if (name === 'ddd') {
      setPhoneError('');
      setDdd(value);
    } else if (name === 'patrimony') {
      setPatrimony(value);
      setPatrimonyError('');
    }
  };

  const submitRequest = () => {
    setLoading(true);
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: `https://api.analisedeacoes.com/lp/partnership/blue3`,
      data: {
        email: email,
        name: name,
        phone: phone ? `${ddd}${phone}` : undefined,
        patrimony,
        origin
      }
    })
    .then(result => {
      setLoading(false);
      Analytics.track('Parceria Blue3', 'Download', 'E-mail enviado com sucesso (Usuário não logado)');

      const showForm = result.data.showForm;
      Bootstrap.hideModal('#blue3partnership-register');

      if (origin === 'eulb') {
        navigate('/parceria-blue3/eulb/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`, patrimony }});
      } else {
        navigate('/parceria-blue3/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`, patrimony }});
      }
    })
    .catch(() => {
      setLoading(false);
      Analytics.track('Parceria Blue3', 'Download', 'Provavelmente o e-mail não foi enviado');
      Bootstrap.hideModal('#blue3partnership-register');
      
      if (origin === 'eulb') {
        navigate('/parceria-blue3/eulb/sucesso/', { state: { name, email, phone: `${ddd}${phone}`} });
      } else {
        navigate('/parceria-blue3/sucesso/', { state: { name, email, phone: `${ddd}${phone}`} });
      }
    });

    // Bootstrap.hideModal('#blue3partnership-register');
    // const showForm = true;
    // navigate('/parceria-blue3/eulb/sucesso/', { state: { showForm, name, email, phone: `${ddd}${phone}`} });
  };

  const handleClickDownload = event => {
    Bootstrap.showModal('#blue3partnership-register');
  };

  const handleClickDownloadNotLogged = event => {
    let error = false;

    if (!(name.length > 3)) {
      setNameError('active');
      error = true;
    }

    if (!Validations.isValidEmail(email)) {
      setEmailError('active');
      error = true;
    }

    if (!Validations.isValidPhone(phone)) {
      setPhoneError('active');
      error = true;
    }

    if (!ddd) {
      setPhoneError('active');
      error = true;
    }

    if (!patrimony) {
      setPatrimonyError('active');
      error = true;
    }

    if (error) {
      return;
    }

    submitRequest();
  };

  const trackAnalytics = () => {
    $(`#blue3partnership #blue3partnership-register .modal-header button`).on('click', () => Analytics.track('Parceria Blue3', `Modal lead`, 'Fechar'));
    $(`#blue3partnership #blue3partnership-register .modal-footer .button-primary`).on('click', () => Analytics.track('Parceria Blue3', `Modal lead`, `Continuar`));
  };

  useEffect(() => {
    $('#blue3partnership .phone-number').keypress(function(event) {
      return isNumber(event, this);
    });

    setOrigin(Origin.get());

    trackAnalytics();
  }, [])

  return (
    <Layout page="blue3partnership" location={location} footer={false} name="parceriablue3">
      <Seo
        title="Análise de Ações + Blue3"
        subtitle="Parceria"
        description="Parceria que faz a diferença na sua carteira de ações."
        image="/images/helpers/cover-parceria-blue3-xp-analise-de-acoes.jpg"
        url="/parceria-blue3/" />

      <section className="presentation">
        <div className="container">
          <div className="row">
            <div className="column-left col-md-8">
              <p className="subtitle">Análise de Ações e Blue3</p>
              <h1 className="title"><span>Parceria que faz a diferença</span> na sua carteira de ações.</h1>
              <p className="description">Conte com a ajuda de especialistas eleitos os melhores do país pela XP para investir em ações. <strong>Parceria entre o Análise de Ações e a Blue3</strong>, te dando todo o apoio que precisa para alcançar o próximo nível nos seus investimentos.</p>
              <p className="description">E o melhor, sem pagar nada a mais por isso, um benefício Análise de Ações para você.</p>
              <button className="button button-primary button-blue3" onClick={handleClickDownload}>Tenho interesse em conhecer</button>
            </div>

            <div className="column-right col-md-4">
              <img className="image" src="/images/helpers/blue3-partnership-presentation.jpg" alt="Parceria Blue3" />
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container">
          <h2 className="title"><span>Suporte completo e qualificado</span> na Bolsa de Valores</h2>

          <div className="features-list row">
            {
              features.map((item, index) => (
                <div className={`${index !== 2 ? 'col-sm-6' : 'col-sm-12'} col-md-4`} key={index}>
                  <div className="features-list-item">
                    <h3 className="subtitle">{item.title}</h3>
                    <p className="description">{item.text}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="container">
          <h2 className="title">Como funciona?</h2>
          
          <div className="steps-list row">
            {
              steps.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="steps-list-item">
                    <span className="steps-number">{index+1}</span>
                    <h3 className="subtitle">{item.title}</h3>
                    <p className="description">{item.text}</p>
                  </div>
                </div>
              ))
            }
          </div>

          <button className="button button-primary button-blue3" onClick={handleClickDownload}>Agendar uma conversa gratuita</button>
        </div>
      </section>

      <section className="feedbacks">
        <div className="container">
          <h2 className="title">O que os clientes falam sobre a Blue3?</h2>
          <p className="description-nps">NPS 94.5</p>
          <p className="description-text">Alto nível de satisfação dos clientes, o maior entre todas as grandes operações da XP.</p>
          
          <div className="feedbacks-list row">
            {
              feedbacks.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="feedbacks-list-item">
                    <p className="text">{item.text}</p>
                    <p className="author">{item.author}</p>
                  </div>
                </div>
              ))
            }
          </div>

          <button className="button button-third button-blue3" onClick={handleClickDownload}>Agendar uma conversa gratuita</button>
        </div>
      </section>

      <section className="rewards">
        <div className="container">
          <h2 className="title">Qualidade comprovada pelos principais reconhecimentos do mercado</h2>
          
          <div className="rewards-list row">
            {
              rewards.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="rewards-list-item">
                  <img src={`/images/helpers/${item}`} alt="Blue3" />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className="media">
        <div className="container">
          <div className="media-list">
            {
              media.map((item, index) => (
                <li className="media-list-item" key={index}>
                  <a href={item.url} target="_blank" rel="noreferrer nofollow">
                    <img src={`/images/helpers/${item.image}`} alt={item.title} />
                  </a>
                </li>
              ))
            }
          </div>
        </div>
      </section>

      <section className="doubts">
        <div className="container">
          <h2 className="title">Ficou com alguma dúvida?</h2>

          <div class="accordion" id="doubts" role="tablist" aria-multiselectable="true">
            {
              doubts.map((item, index) => (
                <div class="card" key={index}>
                  <div class="card-header" role="tab" id={`head-${index}`}>
                    <a data-toggle="collapse" data-parent="#doubts" href={`#doubt-${index}`} className="collapsed" aria-expanded="false" aria-controls={`doubt-${index}`}>
                      <h3>{item.title}</h3>
                    </a>
                  </div>
        
                  <div id={`doubt-${index}`} className="collapse" role="tabpanel" aria-labelledby={`head-${index}`} data-parent="#doubts">
                    <div class="card-body">{item.text}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="container">
          <h2 className="title">Vem fazer parte da Blue3 você também!</h2>

          <div className="highlights-list row">
            {
              highlights.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="highlights-list-item">
                    <h3 className="subtitle">{item.title}</h3>
                    <p className="description">{item.text}</p>
                  </div>
                </div>
              ))
            }
          </div>

          <button className="button button-primary button-blue3" onClick={handleClickDownload}>Agendar uma conversa gratuita sem compromisso</button>
        
          <div className="footer-analise">
            <p>Análise de Ações</p>
            <p>Descomplicamos sua análise fundamentalista</p>

            <a href={`https://www.analisedeacoes.com${origin ? '?utm_source=' + origin + '&utm_medium=' + name : ''}`} target="_blank" rel="noreferrer" className="footer-site-analise" title="Site analisedeacoes.com">
              www.analisedeacoes.com
            </a>

            <a href={`https://www.analisedeacoes.com${origin ? '?utm_source=' + origin + '&utm_medium=' + name : ''}`} target="_blank" rel="noreferrer" className="footer-logo-analise" title="Site analisedeacoes.com">
              <Image src="/images/logo-small-white.svg" alt="Análise de Ações" />
            </a>
          </div>
        </div>
      </section>

      <div id="blue3partnership-register" class="modal modal-small">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <p className="modal-title">Dados para contato</p>
              
              <div className={"row"}>
                <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                  <input type="text" className={"form-control"} placeholder="Nome *" name="name" value={name} onChange={handleInputChange} maxLength={50} required />
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label className={`error ${nameError}`}>Verifique o nome digitado e tente novamente.</label>
                </div>

                <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                  <input type="text" className={"form-control"} placeholder="E-mail *" name="email" value={email} maxLength={80} onChange={handleInputChange} required />
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label className={`error ${emailError}`}>Verifique o e-mail digitado e tente novamente.</label>
                </div>

                <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                  <select value={ddd} onChange={handleInputChange} name="ddd" className="form-control phone-ddd">
                    <option value="">DDD *</option>
                    {
                      ddds.map((value, index) => (
                        <option key={index} value={value}>{value}</option>
                      ))
                    }
                  </select>
                  
                  <input type="tel" className={"form-control phone-number"} placeholder="Celular *" name="phone" value={phone} maxLength={9} onChange={handleInputChange} required />
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label className={`error ${phoneError}`}>Verifique o telefone digitado e tente novamente.</label>
                </div>

                <div className={"select-button input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                  { /* eslint-disable-next-line jsx-a11y/no-onchange */ }
                  <select value={patrimony} onChange={handleInputChange} name="patrimony" className="form-control patrimony">
                    <option value="">Patrimônio investido *</option>
                    {
                      patrimonyList.map((value, index) => (
                        <option key={index} value={value.value}>{value.text}</option>
                      ))
                    }
                  </select>
                  { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                  <label className={`error ${patrimonyError}`}>Selecione o patrimônio investido.</label>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="button button-primary button-blue3" onClick={handleClickDownloadNotLogged} disabled={loading}>{`${loading ? 'Carregando' : 'Continuar'}`}</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

function isNumber(evt, element) {
  var charCode = evt.which;

  if ((charCode !== 45 || $(element).val().indexOf('-') === -1) &&
      (charCode !== 46 || $(element).val().indexOf('.') === -1) &&
      (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export default Blue3PartnershipPage;
